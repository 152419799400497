import { gsap } from "gsap";

import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Form from "./lib/form";

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);

window.addEventListener("resize", function (e) {
  setTimeout(function () {
    vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, 100);
});
window.addEventListener("orientationchange", function (e) {
  setTimeout(function () {
    vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, 100);
});
window.addEventListener("deviceorientation", function (e) {
  setTimeout(function () {
    vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, 100);
});

const appInit = () => {
  document.body.classList.remove("loading");
  if (document.querySelectorAll("video").length) {
    document.querySelector("#start-player-1").src = "./videos/1.mp4";
    document.querySelector("#start-player-2").src = "./videos/2.mp4";
    document.querySelector("#start-player-3").src = "./videos/3.mp4";
    document.querySelector("#start-player-4").src = "./videos/4.mp4";
  }

  if (document.querySelectorAll("form[name='contact-us-form']").length) {
    const contactForm = new Form();
  }

  /* ================================================================== 
                              HEADER
 =================================================================== */
  document.addEventListener("scroll", () => {
    const y = window.pageYOffset;
    if (y > 0) {
      document.querySelector("header").classList.add("short");
    } else {
      document.querySelector("header").classList.remove("short");
    }
  });
  if (document.querySelector("header button.header__button-menu")) {
    document
      .querySelector("header button.header__button-menu")
      .addEventListener("click", () => {
        document.querySelector("header").classList.toggle("active");
      });
  }

  if (document.body.classList.contains("terms-page")) return;

  document.querySelectorAll("[data-target]").forEach((el) => {
    el.addEventListener("click", (e) => {
      const t = e.currentTarget.dataset.target;
      let y = 0;
      switch (t) {
        case "hero":
          y = 0;
          break;
        case "services":
          y = document.querySelector(`#${t}`).offsetTop - 100;
          break;
        default:
          y = document.querySelector(`#${t}`).offsetTop;
          break;
      }
      document.querySelector("header").classList.remove("active");
      gsap.to(window, { duration: 1, scrollTo: y, ease: "power3.inOut" });
      e.preventDefault();
    });
  });

  /* ================================================================== 
                                  FAQ
 =================================================================== */
  document.querySelectorAll("#faq-list .faq__question h3").forEach((el) => {
    el.addEventListener("click", (e) => {
      const p = el.closest(".faq__question").querySelector("p");
      const a = el.closest(".faq__question").classList.contains("active");
      if (a) {
        el.closest(".faq__question").classList.remove("active");
        gsap.to(p, {
          duration: 0.35,
          height: 0,
          ease: "power3.inOut",
          onComplete: () => {
            gsap.set(p, { clearProps: "all" });
          },
        });
      } else {
        el.closest(".faq__question").classList.add("active");
        gsap.set(p, { display: "block" });
        gsap.from(p, { duration: 0.35, height: 0, ease: "power3.inOut" });
      }
    });
  });

  function myHandler(e) {
    console.log(e);
  }

  /* ================================================================== 
                                VIDEOS
 =================================================================== */

  const videoHover = () => {
    document.querySelector(".start .start__list ul").classList.add("hover");
    document.querySelectorAll(".start .start__list ul li").forEach((el) => {
      el.addEventListener("mouseenter", (e) => {
        const v = e.currentTarget.dataset.video;
        gsap.set(".start .start__movie video", { display: "none" });
        gsap.set(`#start-player-${v}`, { display: "block" });
        document.querySelector(`#start-player-${v}`).currentTime = 0;
        document.querySelector(`#start-player-${v}`).play();
      });
    });
  };

  let videoActive = false;
  let videoCounter = 1;
  const videoStart = () => {
    if (videoCounter > 4) {
      videoHover();
      return;
    }
    const li = document.querySelector(
      `.start .start__list ul li:nth-child(${videoCounter})`
    );
    gsap
      .timeline()
      .set(li, { visibility: "visible" })
      .from(li, { duration: 1, opacity: 0, y: 150, ease: "power3.out" })
      .from(
        li.querySelector("p"),
        {
          duration: 1,
          opacity: 0,
          y: 50,
          ease: "power3.out",
        },
        "-=0.8"
      )
      .set(".start .start__movie video", { display: "none" })
      .set(`#start-player-${videoCounter}`, {
        display: "block",
        onComplete: () => {
          const video = document.querySelector(`#start-player-${videoCounter}`);
          video.play();
          videoCounter += 1;
          video.addEventListener("ended", videoStart, false);
        },
      });
  };

  /* ================================================================== 
                          SCROLL ANIMATIONS
 =================================================================== */

  // Hero
  ScrollTrigger.create({
    trigger: "#hero",
    start: "top center",
    end: "bottom center",
    onToggle: (self) => {
      if (self.isActive) {
        document.querySelector("header").dataset.active = "hero";
      }
    },
  });

  // Services
  const servicesAnimation = gsap
    .timeline({
      scrollTrigger: {
        trigger: "#services",
        start: "top 50%",
      },
      onComplete: () => {
        servicesAnimation.scrollTrigger.disable();
      },
    })
    .set(".services__list", { visibility: "visible" })
    .from(".services__list li", {
      duration: 1,
      opacity: 0,
      y: 100,
      stagger: 0.2,
      ease: "power2.out",
    });

  ScrollTrigger.create({
    trigger: "#services",
    start: "top center",
    end: "bottom center",
    onToggle: (self) => {
      if (self.isActive) {
        document.querySelector("header").dataset.active = "services";
      }
    },
  });

  // Makes
  const makesAnimation = gsap
    .timeline({
      scrollTrigger: {
        trigger: ".makes",
        start: "top 30%",
      },
      onComplete: () => {
        makesAnimation.scrollTrigger.disable();
      },
    })
    .set(".makes .makes__list ul", { visibility: "visible" })
    .from(".makes .makes__list ul li", {
      duration: 1.5,
      opacity: 0,
      stagger: 0.15,
    });

  // Videos
  ScrollTrigger.create({
    trigger: "#how-to-start",
    start: "top center",
    end: "bottom center",
    onEnter: () => {
      if (!videoActive) {
        videoActive = true;
        if (window.innerWidth > 960) {
          videoStart();
        } else {
          gsap
            .timeline()
            .set(".start .start__list ul li", { visibility: "visible" })
            .from(".start .start__list ul li", {
              duration: 1,
              opacity: 0,
              y: 150,
              stagger: 0.2,
              ease: "power3.out",
            })
            .from(
              ".start .start__list ul li p",
              {
                duration: 1,
                opacity: 0,
                y: 50,
                stagger: 0.2,
                ease: "power3.out",
              },
              "-=1.4"
            );
        }
      }
    },
    onToggle: (self) => {
      if (self.isActive) {
        document.querySelector("header").dataset.active = "start";
      }
    },
  });

  // FAQ
  const faqAnimation = gsap
    .timeline({
      scrollTrigger: {
        trigger: "#faq-list",
        start: "top 30%",
      },
      onComplete: () => {
        faqAnimation.scrollTrigger.disable();
      },
    })
    .set(".faq__questions, .faq__actions", { visibility: "visible" })
    .from(".faq__questions .faq__question", {
      duration: 0.6,
      opacity: 0,
      y: 100,
      stagger: 0.15,
      ease: "power4.out",
    })
    .from(
      ".faq__actions",
      {
        duration: 0.6,
        opacity: 0,
        y: 100,
        stagger: 0.15,
        ease: "power4.out",
      },
      "-=0.4"
    );
  ScrollTrigger.create({
    trigger: "#faq-list",
    start: "top center",
    end: "bottom center",
    onToggle: (self) => {
      if (self.isActive) {
        document.querySelector("header").dataset.active = "faq";
      }
    },
  });

  // About
  const aboutAnimation = gsap
    .timeline({
      scrollTrigger: {
        trigger: "#about",
        start: "top 50%",
      },
      onComplete: () => {
        aboutAnimation.scrollTrigger.disable();
      },
    })
    .set(
      ".about .about__details .circle-1, .about .about__details .circle-2, .about .wrapper .about__details li span",
      { visibility: "visible" }
    )
    .from(
      ".about .about__details .circle-1",
      {
        duration: 0.6,
        scale: 0,
        stagger: 0.3,
      },
      "start"
    )
    .from(
      ".about .about__details .circle-2",
      {
        duration: 0.6,
        scale: 0,
        stagger: 0.2,
      },
      "start"
    )
    .from(
      ".about .wrapper .about__details li span",
      {
        duration: 0.8,
        y: 50,
        opacity: 0,
        stagger: 0.2,
        ease: "power3.out",
      },
      "-=0.5"
    );
  ScrollTrigger.create({
    trigger: "#about",
    start: "top center",
    end: "bottom center",
    onToggle: (self) => {
      if (self.isActive) {
        document.querySelector("header").dataset.active = "about";
      }
    },
  });

  // Team
  const teamAnimation = gsap
    .timeline({
      scrollTrigger: {
        trigger: ".team",
        start: "top 50%",
      },
      onComplete: () => {
        teamAnimation.scrollTrigger.disable();
      },
    })
    .set(".team .team__list", { visibility: "visible" })
    .from(".team .team__list li", {
      duration: 0.8,
      opacity: 0,
      y: 100,
      stagger: 0.15,
      ease: "power3.out",
    });

  ScrollTrigger.create({
    trigger: "#contacts",
    start: "top center",
    end: "bottom center",
    onToggle: (self) => {
      if (self.isActive) {
        document.querySelector("header").dataset.active = "contacts";
      }
    },
  });
};

window.onload = appInit;
