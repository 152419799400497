// import firebase from 'firebase/app';
// import "firebase/database";
import { gsap } from "gsap";

export default class Form {
  constructor() {
    this.errors = {
      name: true,
      company: true,
      budget: true,
      email: true,
      message: true,
    };
    this.errorsClass = {
      name: false,
      company: false,
      budget: false,
      email: false,
      message: false,
    };
    this.captcha = window.grecaptcha;

    document.querySelector("#spp-764N3G").addEventListener("submit", (e) => {
      this.submit(e);
    });
    document
      .querySelectorAll(".form-control input, .form-control textarea")
      .forEach((el) => {
        el.addEventListener("focus", (e) => {
          this.focus(e);
        });
      });
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  validate() {
    const fName = document.querySelector("#name_f").value.trim().length > 2;
    const lName = document.querySelector("#name_l").value.trim().length > 2;
    const email = this.validateEmail(
      document.querySelector("#email").value.trim()
    );
    const message = document.querySelector("#field_3").value.trim().length > 9;

    if (!fName) {
      document
        .querySelector("#name_f")
        .closest(".form-control")
        .classList.add("error");
    }
    if (!lName) {
      document
        .querySelector("#name_l")
        .closest(".form-control")
        .classList.add("error");
    }
    if (!email) {
      document
        .querySelector("#email")
        .closest(".form-control")
        .classList.add("error");
    }
    if (!message) {
      document
        .querySelector("#field_3")
        .closest(".form-control")
        .classList.add("error");
    }

    if (fName && lName && email && message) {
      return true;
    } else {
      return false;
    }
  }
  focus(e) {
    e.target.closest(".form-control").classList.remove("error");
  }

  submit(e) {
    //this.thankyou();
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    if (this.validate()) {
      this.captcha.ready(() => {
        this.captcha.execute('6LcSDsUhAAAAAOhroKAZ1NKHESgxZgj1bXI6ZCpe', { action: 'submit' })
          .then((token) => {
            data.append('g_captcha', token);
            const params = {
              body: JSON.stringify(Object.fromEntries(data)),
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              }
            };
            fetch(`${form.action}`, params)
              .then((res) => {
                if (res.status === 200) this.thankyou();
              })
              .catch((error) => {
                console.error("Error:", error)
              }); // parses response to JSON
          })
          .catch((ex) => {
            console.error('Captcha error', ex);
          });
      });
    }
  }

  thankyou() {
    gsap
      .timeline()
      .to("form .form-control, form .form-submit, form .form-sign", {
        duration: 0.4,
        y: -50,
        opacity: 0,
        ease: "power2.in",
        stagger: 0.15,
        onComplete: () => {
          document
            .querySelectorAll(
              ".contacts__form_body form input, .contacts__form_body form textarea"
            )
            .forEach((el) => {
              el.value = "";
            });
        },
      })
      .set(".contacts__form_thank", { visibility: "visible" }, "-=0.3")
      .from(
        ".contacts__form_thank",
        {
          duration: 0.6,
          opacity: 0,
          y: 50,
          ease: "power2.out",
        },
        "-=0.3"
      )
      .to(
        ".contacts__form_thank",
        {
          duration: 0.4,
          y: -50,
          opacity: 0,
          ease: "power2.in",
        },
        "+=3"
      )
      .set("form .form-control, form .form-submit, form .form-sign", {
        y: 50,
      })
      .to("form .form-control, form .form-submit, form .form-sign", {
        duration: 0.6,
        y: 0,
        opacity: 1,
        ease: "power2.out",
        stagger: 0.15,
      })
      .set(".contacts__form_thank", {
        clearProps: "all",
      });
  }
}
